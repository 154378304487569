import React from "react"
import moment from "moment"
import date from "date-and-time";
import { Button, Modal, Tabs, Tab} from 'react-bootstrap';
import "../assets/shiftModal.css"
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2"
import 'animate.css'; //Aminitions for arlets for

export default class ShiftModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:props.show,
            clientid : 0,
            name : "",
            serviceid:0,
            address: "",
            sdt:"",
            starttime:"",
            endtime:"",
            staffid:null,
            priceperhour:0,
            calculatedDuration:0,
            key:props.tabkey,
            shiftListToBeEdited:[],
            shifts:[],
            selectedShiftIds:[],
            weekstart: this.props.weekstart,
            weekend : this.props.weekend,
            weekdays : [],
            upcommingweeks:[],
            followingweeks: [],
            selectedrecurrancedates: []
        }
    }

    weekDays = (data) =>{
        var dates=[];
        var pos=0;
        while(moment(data.weekstart).add(pos,'days').format('MM/DD/YYYY')<=moment(data.weekend).format('MM/DD/YYYY')){
            dates.push(moment(data.weekstart).add(pos,'days').format('MM/DD/YYYY'));
            pos++;
        }
        this.setState({ selectedrecurrancedates:[], weekdays: dates});
    }
    followingWeeks = (data) => {
        var start = moment(data.weekstart).format('MM-DD-YYYY');
        start = moment(start).add(0, 'days').format('MM-DD-YYYY');
        var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
        var arr = [];
        for (let index = 0; index < 4; index++) {
          var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
          arr.push({ weekstart: start, weekend: end });
          start = moment(end).add(1, 'days').format('MM-DD-YYYY');
        }
        this.setState({followingweeks: arr});
        this.weekDays(data);

    } 
    componentDidUpdate(prevProps) {
        if (prevProps.tabkey !== this.props.tabkey) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({key: this.props.tabkey})
        }
        if (prevProps.show !== this.props.show) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({show: this.props.show})
          }
        //   console.log(this.props.data.shifts)
        if (prevProps.data.shifts !== this.props.data.shifts) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.setState({shifts: this.props.data.shifts})
          }

        if (prevProps.weekend !== this.props.weekend) {
            this.weekDays({weekstart: this.props.weekstart, weekend: this.props.weekend});
            this.followingWeeks({weekstart: this.props.weekstart, weekend: this.props.weekend});
          }
      }


    calculateDurationOfShift = (params) =>{
        var starttime= date.parse(params.starttime.split(":")[0]+":"+params.starttime.split(":")[1], 'HH:mm:ss A',true);
        var endtime =date.parse(params.endtime.split(":")[0]+":"+params.endtime.split(":")[1], 'HH:mm:ss A',true);
        var  duration = date.subtract(endtime, starttime).toHours();
        duration= Number((duration).toFixed(2));
        duration = duration<0?24+duration:duration;
        this.setState({calculatedDuration:duration})
        return duration;
    }
    setKey=(key)=>{
        this.setState({key:key})
    }

    onCloseModal = () =>{
        if (this.state.shiftListToBeEdited.length==0) {
            this.props.close(); this.setState({shiftListToBeEdited:[], selectedShiftIds:[]})
        }else{
            var swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success m-1',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
              swalWithBootstrapButtons.fire({
                showCancelButton: true,
                confirmButtonText: 'Save edited changes!',
                cancelButtonText: 'Discard edited changes!',
                reverseButtons: true,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              }).then(answr=>{
                if(answr.isConfirmed){
                    this.props.updateParticipantShifts(this.state.shiftListToBeEdited).then(res=>{this.props.close();this.setState({shiftListToBeEdited: []})});
                }else{
                    this.props.close(); this.setState({shiftListToBeEdited:[], selectedShiftIds:[]})
                }
              })
        }
    }
    
    render(){
        return(
        <>
            <Modal size="lg" className="shiftModal participantShiftsModal" show={this.state.show} onHide={()=>{this.setState({selectedrecurrancedates:[]});this.props.close()}} >
               
                    <h3 style={{display: "flex", justifyContent: "center",alignItems: "center", marginBottom:"2%", marginTop:"1%"}}> {this.props.data.selectedClient.name}</h3>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={(k) => this.setKey(k)}
                    className="mb-3"
                    >
                        <Tab eventKey="home" title="Add New Shift">
                        <form onSubmit={(event)=>{this.state.selectedrecurrancedates.push(this.state.sdt?this.state.sdt:this.props.data.day);this.props.executeSaveShift({clientid: this.state.clientid==0?this.props.data.selectedClient.clientid:this.state.clientid, staffid:this.state.staffid,starttime: this.state.starttime, endtime:this.state.endtime, costperhour:this.state.costperhour,address:this.state.address, sdt:this.state.sdt?this.state.sdt:this.props.data.day, selectedrecurrancedates: this.state.selectedrecurrancedates, serviceid:this.state.serviceid}, event)}}>
                            
                            <Modal.Body>
                                <div className="row">
                                    <div class="mb-3 col-6">
                                        <label for="exampleInputEmail1" class="form-label input-labels">Participant</label>
                                        <Typeahead
                                            allowNew
                                            id="custom-selections-example"
                                            newSelectionPrefix="Participant: "
                                            options={this.props.clients}
                                            defaultInputValue={this.props.data.selectedClient.name}
                                            onChange={(event)=>{
                                                if(event[0]){
                                                    this.setState({address:event[0].address,clientid: event[0].value})
                                                    this.props.getParticipantServices(event[0].value);
                                                }
                                            }}
                                            placeholder="Seleact Participant..."
                                        />
                                    </div>
                                    <div class="mb-3 col">
                                        <label for="exampleInputPassword1" class="form-label input-labels">Service</label>
                                        <Typeahead
                                            className=".form-control-inputs"
                                            allowNew
                                            id="custom-selections-example"
                                            newSelectionPrefix="Service: "
                                            options={this.props.participantServices}
                                            onChange={(event)=>{
                                                if(event[0]){
                                                    this.setState({costperhour:event[0].servicePrice, serviceid: event[0].value})
                                                }
                                            }}
                                            placeholder="Select Particpant Service..."
                                        />
                                    </div>
                                    </div>
                                    <div className="row"> 
                                        <div class="mb-3 col-7">
                                            <label for="location" class="form-label input-labels">Participant Location</label>
                                                <input defaultValue={this.state.address?this.state.address:this.props.data.selectedClient.address} type="text" class="form-control border-primary" id="location" aria-describedby="emailHelp"/>
                                            <div id="emailHelp" class="form-text text-alert">We'll never share your email.</div>
                                        </div>
                                        <div class="mb-4 col">
                                            <label for="date" class="form-label input-labels">Staff Working with Participant</label>
                                            <Typeahead
                                            allowNew
                                            id="custom-selections-example"
                                            newSelectionPrefix="Participant: "
                                            options={this.props.workers}
                                            onChange={(event)=>{
                                                if(event[0]){
                                                    this.setState({staffid:event[0].value});
                                                }
                                            }}
                                            placeholder="Seleact Staff to work with the Participant..."
                                        />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="mb-3 col">
                                            <label for="date" class="form-label input-labels">Date</label>
                                                <DatePicker className="form-control border-primary" selected={new Date(this.state.sdt?this.state.sdt:this.props.data.day)} onChange={(date)=>{this.setState({sdt:moment(date)})}} />
                                        </div>
                                        <div class="mb-2 col">
                                            <label for="starttime" class="form-label input-labels">Start Time</label>
                                            <input required type="time" onChange={(event)=>{this.setState({starttime:event.target.value}); this.calculateDurationOfShift({starttime: event.target.value, endtime: this.state.endtime});}} class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                        </div>
                                        <div class="mb-2 col">
                                            <label for="endtime" class="form-label input-labels">End Time</label>
                                            <input required onChange={(event)=>{this.setState({endtime:event.target.value}); this.calculateDurationOfShift({starttime: this.state.starttime, endtime: event.target.value})}} type="time" class="form-control border-primary" id="endtime"/>
                                        </div>
                                        <div class="mb-2 col">
                                            <label for="price" class="form-label input-labels">Price per Hour</label>
                                            <input onChange={(event)=>{this.setState({costperhour:event.target.value})}} defaultValue={this.state.costperhour} type="amount" class="form-control border-primary" id="costperhour" name="costperhour"/>
                                        </div>
                                        <div class="mb-2 col">
                                            <label for="exampleInputPassword1" class="form-label input-labels">Total Cost</label>
                                            <input value={`$${parseFloat(this.state.calculatedDuration*this.state.costperhour).toFixed(2)}`} type="text" class="form-control border-primary" id="exampleInputPassword1"/>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <label for="exampleInputPassword1" class="form-label input-labels">Select Week</label>
                                            <div id="prefetch">
                                                <select onChange={(event)=>{this.weekDays(this.state.followingweeks[this.state.followingweeks.findIndex(x=>x.weekstart==event.target.value)])}} className="form-control">
                                                    {
                                                        this.state.followingweeks.map(item=>
                                                            <option value={`${item.weekstart}`} key={item} >{item.weekstart} to {item.weekend}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <label for="exampleInputPassword1" class="form-label input-labels">Select Days for which the shift shall be recurring</label>
                                            <div className="row">
                                                {
                                                    this.state.weekdays.map(item=>
                                                        <button onClick={(event)=>{
                                                            var arr = this.state.selectedrecurrancedates;
                                                            var index = arr.findIndex(x => x == event.target.value);
                                                            if (index >= 0) {
                                                                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                                            } else {
                                                                arr.push(event.target.value);
                                                            }
                                                            this.setState({selectedrecurrancedates:arr});
                                                            }} type="button" value={item} class={moment(this.state.sdt?this.state.sdt:this.props.data.day)>=moment(item)?`btn position-relative m-1 col form-control border-primary recurring-days disabled`:`btn position-relative  m-1 col form-control border-primary recurring-days`} >
                                                            {moment(item).format("ddd")}
                                                            {this.state.selectedrecurrancedates.includes(item)?<span class="position-absolute top-0 start-100 translate-middle p-2 bg-success border border-light rounded-circle"></span>:""}
                                                        </button>
                                                        )
                                                }
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="danger" onClick={()=>{this.setState({address:"", selectedrecurrancedates : [],starttime:"",sdt:"",endtime:"",clientid:"",serviceid:"", costperhour:0});this.props.close()}}>
                                            Close
                                        </Button>
                                        <Button type="submit" variant="primary" >
                                            Save Shift
                                        </Button>
                                    </Modal.Footer>
                                </form>
                            </Tab>
                            {/* SECOND TAB ON MODAL | PARTICIPANT SHIFTS */}
                            <Tab  eventKey="profile" title="Participant Shift(s)">
                                <Modal.Body>
                                    <div className="table-shifts"> 
                                        <table class="table table-hover">
                                            <thead>
                                                <tr className="participant-shift-row">
                                                <th  scope="col">
                                                    <input
                                                        checked={this.state.selectedShiftIds.length==this.state.shifts.length&&this.state.shifts.length!=0?"checked":""}
                                                        onClick={()=>{
                                                            var list=[];
                                                            if(this.state.selectedShiftIds.length>0){
                                                                this.setState({selectedShiftIds:[]})
                                                            }else{
                                                                this.state.shifts.forEach(element=>{
                                                                    list.push(element.id);
                                                                })
                                                            }
                                                            this.setState({selectedShiftIds:list});
                                                        }}
                                                     type="checkbox">
                                                    </input></th>
                                                <th  scope="col">Date</th>
                                                <th  scope="col">Start</th>
                                                <th scope="col">End </th>
                                                <th scope="col">Service</th>
                                                <th scope="col">Staff</th>
                                                <th  scope="col">Cost</th>
                                                <th  scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.shifts.map(item=>
                                                        <tr className={!item.approved?"participant-shift-row":item.approved&&!item.confirmed?"participant-shift-row day-shift-approved":"participant-shift-row day-shift-confirmed"}>
                                                            <td>
                                                                <input 
                                                                    checked={this.state.selectedShiftIds.findIndex(x=>x==item.id)>=0?"checked":""} 
                                                                    onClick={(event)=>{
                                                                        var list=this.state.selectedShiftIds;
                                                                        const index=list.findIndex(x=>x==event.target.value);
                                                                        if(index>=0){
                                                                            list = list.slice(0, index).concat(list.slice(index + 1, list.length));
                                                                        }else{
                                                                            list.push(event.target.value);
                                                                        }
                                                                        this.setState({selectedShiftIds:list});
                                                                    }}
                                                                    value={item.id} type="checkbox" >
                                                                </input></td>
                                                            <td>
                                                                <div id={`date-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-date-${item.id}`).style.display='block'
                                                                        document.getElementById(`date-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`date-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${moment(item.sdt).format("ddd")} ${moment(item.sdt).format("DD")} ${moment(item.sdt).format("MMM")}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-date-${item.id}`}>
                                                                    
                                                                    <input id={item.id} type="date" className="form-control border-primary" defaultValue={item.sdt}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`date-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        onChange={(event)=>{
                                                                            document.getElementById(`date-${item.id}`).innerHTML = `${moment(event.target.value).format("ddd")} ${moment(event.target.value).format("DD")} ${moment(event.target.value).format("MMM")}`;
                                                                            
                                                                            var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.sdt=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item)
                                                                                }else{
                                                                                    list[index].sdt=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                    ></input>

                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`date-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-date-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`starttime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-starttime-${item.id}`).style.display='block'
                                                                        document.getElementById(`starttime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`starttime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.starttime.split(":")[0]}:${item.starttime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-starttime-${item.id}`}>
                                                                    <input defaultValue ={item.starttime} 
                                                                    onKeyDown={(event)=>{
                                                                        if(event.key=="Enter"){
                                                                            document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                        }
                                                                    }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`starttime-${item.id}`).innerHTML = event.target.value;
                                                                                
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.starttime=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].starttime=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    {/* <div className="row"> */}
                                                                        <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Cancel</i> 
                                                                            <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                                document.getElementById(`starttime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-starttime-${item.id}`).style.display='none';
                                                                            }}>Done</i>
                                                                    {/* </div> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`endtime-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-endtime-${item.id}`).style.display='block'
                                                                        document.getElementById(`endtime-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i id={`endtime-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"></i> {`${item.endtime.split(":")[0]}:${item.endtime.split(":")[1]}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-endtime-${item.id}`}>
                                                                    <input defaultValue={item.endtime}
                                                                     onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                     onChange={(event)=>{
                                                                            var list= this.state.shiftListToBeEdited;
                                                                            const index= list.findIndex(x=>x.id==item.id);
                                                                            document.getElementById(`endtime-${item.id}`).innerHTML = event.target.value;
                                                                            if(index<0){
                                                                                item.endtime=event.target.value;
                                                                                this.state.shiftListToBeEdited.push(item);
                                                                            }else{
                                                                                list[index].endtime=event.target.value
                                                                            }
                                                                            this.setState({shiftListToBeEdited: list});
                                                                        }}
                                                                     type="time" class="form-control border-primary" id="starttime" aria-describedby="emailHelp"/>
                                                                    
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`endtime-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-endtime-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`service-${item.id}`} onClick={()=>{
                                                                    document.getElementById(`edit-service-${item.id}`).style.display='block'
                                                                    document.getElementById(`service-${item.id}`).style.display='none';
                                                                }}>
                                                                    <i id={`service-${item.id}`} onClick={()=>{}} class="bi bi-pencil-square"> </i>{item.tbl_support_service.support_service}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-service-${item.id}`}>
                                                                    <Typeahead
                                                                        className=".form-control-inputs"
                                                                        allowNew
                                                                        id={`edit-service-${item.id}`}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`service-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        newSelectionPrefix="Service: "
                                                                        options={this.props.participantServices}
                                                                        onChange={(event)=>{
                                                                            if(event[0]){
                                                                                document.getElementById(`service-${item.id}`).innerHTML = event[0].label;
                                                                                document.getElementById(`service-${item.id}`).style.display='block';
                                                                                document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.serviceid=event[0].value;
                                                                                    item.tbl_support_service.support_service=event[0].label;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].serviceid=event[0].value;
                                                                                    list[index].tbl_support_service.support_service=event[0].label;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                            }
                                                                        }}
                                                                        placeholder="Select Particpant Service..."
                                                                    />
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`service-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-service-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.tbluser?
                                                                    <div id={`staff-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                        document.getElementById(`staff-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i>
                                                                    {`${item.tbluser.lastname} ${item.tbluser.firstname}`}</div>
                                                                    :
                                                                    <i id={`staff-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-staff-${item.id}`).style.display='block'
                                                                        document.getElementById(`staff-${item.id}`).style.display='none';
                                                                    }} class="bi bi-pencil-square">Asign Staff</i>
                                                                } 
                                                                <div style={{"display":"none"}} id={`edit-staff-${item.id}`}>
                                                                    <Typeahead
                                                                        // style={{"display":"none"}}
                                                                        className=".form-control-inputs"
                                                                        allowNew
                                                                        id={`edit-staff-${item.id}`}
                                                                        newSelectionPrefix="Staff: "
                                                                        options={this.props.workers}
                                                                        onKeyDown={(event)=>{
                                                                            if(event.key=="Enter"){
                                                                                document.getElementById(`staff-${item.id}`).style.display='block'
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                            }
                                                                        }}
                                                                        onChange={(event)=>{
                                                                            if(event[0]){
                                                                                document.getElementById(`staff-${item.id}`).innerHTML = event[0].label;
                                                                                document.getElementById(`staff-${item.id}`).style.display='block';
                                                                                document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.staffid=event[0].value;
                                                                                    //item.tbleuser=event[0].label;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].staffid=event[0].value;
                                                                                    //list[index].staffid=event[0].label;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                            }
                                                                        }}
                                                                        placeholder="Select Staff Service..."
                                                                    />
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`staff-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-staff-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div id={`costperhour-${item.id}`} onClick={()=>{
                                                                        document.getElementById(`edit-costperhour-${item.id}`).style.display='block'
                                                                        document.getElementById(`costperhour-${item.id}`).style.display='none';
                                                                    }}>
                                                                    <i class="bi bi-pencil-square"></i> {`${item.costperhour}`}
                                                                </div>
                                                                <div style={{"display":"none"}} id={`edit-costperhour-${item.id}`}>
                                                                    <input defaultValue ={item.costperhour}
                                                                     onKeyDown={(event)=>{
                                                                         if(event.key=="Enter"){
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                         }
                                                                     }}
                                                                     onChange={(event)=>{
                                                                                document.getElementById(`costperhour-${item.id}`).innerHTML = event.target.value;
                                                                                // document.getElementById(`costperhour-${item.id}`).style.display='block';
                                                                                // document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                                var list= this.state.shiftListToBeEdited;
                                                                                const index= list.findIndex(x=>x.id==item.id);
                                                                                if(index<0){
                                                                                    item.costperhour=event.target.value;
                                                                                    this.state.shiftListToBeEdited.push(item);
                                                                                }else{
                                                                                    list[index].costperhour=event.target.value;
                                                                                }
                                                                                this.setState({shiftListToBeEdited: list});
                                                                                
                                                                        }} type="text" class="form-control border-primary md-col-10" id="costperhour" aria-describedby="emailHelp"/>
                                                                   <div className="md-col-2">
                                                                    <i type="button" class="bi  bi-x-circle-fill cancell-icon m-1" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Cancel</i> 
                                                                        <i type="button" class="bi  bi-x-circle-fill bi-check2-all m-1 text-success" onClick={()=>{
                                                                            document.getElementById(`costperhour-${item.id}`).style.display='block'
                                                                            document.getElementById(`edit-costperhour-${item.id}`).style.display='none';
                                                                        }}>Done</i>
                                                                   </div>
                                                                    
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                            </Modal.Body>
                                <Modal.Footer>
                                <Button variant="dark" onClick={()=>this.onCloseModal()}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={()=>{this.props.executeDeleteShift(this.state.selectedShiftIds); this.setState({selectedShiftIds:[]})}}>
                                    Delete
                                </Button>
                                <Button variant="success" onClick={()=>{this.props.executeApproveShift(this.state.selectedShiftIds)}}>
                                    Approve
                                </Button>
                                <Button variant="warning" onClick={()=>{this.props.executeConfirmShift(this.state.selectedShiftIds)}}>
                                    Confirm
                                </Button>
                                <Button variant="primary" onClick={()=>{
                                    //Now sending updates to the server
                                    this.props.updateParticipantShifts(this.state.shiftListToBeEdited).then(res=>this.setState({shiftListToBeEdited: []}));
                                    }}>
                                    Save {this.state.shiftListToBeEdited.length>0?`(${this.state.shiftListToBeEdited.length})`:``}
                                </Button>
                            </Modal.Footer>
                        </Tab>
                    </Tabs>
                
            </Modal>
        </>
        )
    }

}