import React from "react";
import moment from "moment";
import axios from "axios";
import "../assets/participant.css"
import RosterController from "../controllers/roster";
import ToolsController from '../controllers/tools' 


export default class Participants extends React.Component { 
    constructor(props) {
        super(props);
        this.state={
            logs : [],
            weekstart : "",
            weeked: ""

        }
    }
    componentDidMount(){
        var start=ToolsController.calculateWeekRange().start;
        var end = ToolsController.calculateWeekRange().end;
        this.setState({weekStart:start, weekEnd:end});
        this.getLogs({start:start, end: end});

    }

    getLogs = (data) => {
        axios.get(RosterController.extractToken().api+"audit/data?token="+RosterController.extractToken().token+"&start="+data.start+"&end="+data.end).then(res=>{
            this.setState({logs : res.data})
            console.log(res.data)
        }).catch(error=>{
            console.log(error);
        })
    }
   
    render(){
        return(<>
                <div className="row m-3" style={{marginBottom:"10px"}}>
                        
                    <div className="col" style={{float:"right"}}>
                        
                        <div style={{float:"right"}} className="tool-button col btn-group">
                                <button onClick={()=>{
                                    var start=ToolsController.getPreviousWeek({start:this.state.weekStart, end : this.state.weekEnd}).start;
                                    var end = ToolsController.getPreviousWeek({start:this.state.weekStart, end : this.state.weekEnd}).end;
                                    this.setState({weekStart: start, weekEnd: end});
                                    this.getLogs({start:start,end:end}).then(res=>{
                                        this.setState({services:res})
                                    })
                                    this.getLogs({start:start,end:end}).then(res=>{
                                        this.setState({shifts:res})
                                    })
                                    
                                }} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                                <button type="button" className="btn btn-sm btn-outline-secondary">
                                    <span data-feather="calendar"></span>{`${moment(this.state.weekStart).format('ddd')} ${moment(this.state.weekStart).format('DD')} ${moment(this.state.weekStart).format('MMM')}`} - {moment(this.state.weekEnd).format('ddd')} {moment(this.state.weekEnd).format('DD')} {moment(this.state.weekEnd).format('MMM')}</button>
                                <button onClick={()=>{
                                    var start=ToolsController.getNextWeek({start:this.state.weekStart, end : this.state.weekEnd}).start;
                                    var end = ToolsController.getNextWeek({start:this.state.weekStart, end : this.state.weekEnd}).end;
                                    this.setState({weekStart: start, weekEnd: moment(end)});
                                    this.getLogs({start:start,end:end}).then(res=>{
                                        this.setState({services:res})
                                    })
                                    this.getLogs({start:start,end:end}).then(res=>{
                                        this.setState({shifts:res})
                                    })
                                    
                                }} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                            </div>
                            
                        </div>
                    </div>
                <div>
                <table   style={{marginTop:"1%"}} className="table table--roster table-borderless position-relative table-hover">
                    <thead style={{display:""}} className="thead-p-">
                        <tr className="tr-p"  style={{"borderBottom":"solid #000000", 'width':'100%'}}>
                            <th style={{width:"5%"}} className="table-col p" scope="col" >Log ID</th>
                            <th style={{width:"20%",}} className="table-col p" scope="col" >User</th>
                            <th style={{width:"5%"}} className="table-col p" scope="col" >User ID</th>
                            <th style={{width:"52%"}} className="table-col p" scope="col" >Action Performed By the user</th>
                            <th style={{width:"12%"}} className="table-col p" scope="col" >Date</th>
                            <th style={{width:"12%"}} className="table-col p" scope="col" >Time</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-p" style={{display:""}} >
                        {
                            this.state.logs.map(item=>
                                <tr className="tr-p" style={{'width':'100%'}}>
                                    <td className="table-col  p-r" >{item.id}</td>
                                    <td  className="table-col p-r"  >{item.tbluser.lastname} {item.tbluser.firstname}</td>
                                    <td  className="table-col p-r"  >{item.userid}</td>
                                    <td className="table-col p-r"  >{item.event}</td>
                                    <td  className="table-col p-r"  >{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className="table-col p-r"  >{moment(item.createdAt).format('HH:mm:ss')}</td>
                                   
                                </tr>
                            )
                        }
            
                    </tbody>
                </table>
                
            </div>
        </>)
    }
}
