import React from "react";
import moment from "moment";
import '../assets/reports.css'
import ShiftsGraph from "./graphs/shifts"
import Services from "./graphs/services"
import Controller from "../controllers/reports"
import ToolsController from '../controllers/tools' 
import RosterController from '../controllers/roster';

export default class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state={
            services:[],
            shifts:[],
            weekStart:"",
            weekEnd:""
        }
    }
    componentDidMount(){
        var start=ToolsController.calculateWeekRange().start;
        var end = ToolsController.calculateWeekRange().end;
        this.setState({weekStart:start, weekEnd:end})
        Controller.getServicesStatisticsForTheWeek({start:start,end:end}).then(res=>{
            this.setState({services:res})
        })
        Controller.getSfihtsStatisticsForTheWeek({start:start,end:end}).then(res=>{
            this.setState({shifts:res})
        })
    }
    render(){
        return(
            <>
                <div className="reports-page" style={{marginTop:"1%"}}>
                    <div className="row" style={{marginBottom:"10px"}}>
                        
                        <div className="col" style={{float:"right"}}>
                        <a href={`${RosterController.extractToken().api+'reports/excel?approved=true&token='+RosterController.extractToken().token+"&start="+this.state.weekStart+"&end="+this.state.weekEnd}`}><button style={{float:"right"}} type="button" className=" btn btn-outline-success btn-sm col"><i class="bi bi-download"></i> Export Approved Shifts</button></a>
                        <a href={`${RosterController.extractToken().api+'reports/excel?confirmed=true&token='+RosterController.extractToken().token+"&start="+this.state.weekStart+"&end="+this.state.weekEnd}`}><button style={{float:"right"}} type="button" className=" btn btn-outline-primary btn-sm col"><i class="bi bi-download"></i> Export Confirmed Shifts</button></a>
                        <div style={{float:"right"}} className="tool-button col btn-group">
                                <button onClick={()=>{
                                    var start=ToolsController.getPreviousWeek({start:this.state.weekStart, end : this.state.weekEnd}).start;
                                    var end = ToolsController.getPreviousWeek({start:this.state.weekStart, end : this.state.weekEnd}).end;
                                    this.setState({weekStart: start, weekEnd: end});
                                    Controller.getServicesStatisticsForTheWeek({start:start,end:end}).then(res=>{
                                        this.setState({services:res})
                                    })
                                    Controller.getSfihtsStatisticsForTheWeek({start:start,end:end}).then(res=>{
                                        this.setState({shifts:res})
                                    })
                                    
                                }} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                                <button type="button" className="btn btn-sm btn-outline-secondary">
                                    <span data-feather="calendar"></span>{`${moment(this.state.weekStart).format('ddd')} ${moment(this.state.weekStart).format('DD')} ${moment(this.state.weekStart).format('MMM')}`} - {moment(this.state.weekEnd).format('ddd')} {moment(this.state.weekEnd).format('DD')} {moment(this.state.weekEnd).format('MMM')}</button>
                                <button onClick={()=>{
                                    var start=ToolsController.getNextWeek({start:this.state.weekStart, end : this.state.weekEnd}).start;
                                    var end = ToolsController.getNextWeek({start:this.state.weekStart, end : this.state.weekEnd}).end;
                                    this.setState({weekStart: start, weekEnd: end});
                                    Controller.getServicesStatisticsForTheWeek({start:start,end:end}).then(res=>{
                                        this.setState({services:res})
                                    })
                                    Controller.getSfihtsStatisticsForTheWeek({start:start,end:end}).then(res=>{
                                        this.setState({shifts:res})
                                    })
                                    
                                }} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row mt-2" style={{width:"100%",marginLeft:"1%"}}>
                        <div className="col-md-6"><Services data={this.state.services}/></div>
                        <div className="col-md-6"><ShiftsGraph data={this.state.shifts}/></div>
                    </div>
                    <div className="row mt-2" style={{width:"100%",marginLeft:"1%"}}>
                        <div className="col-md-6"><h5 style={{fontWeight:"bold"}}>Employee Attendancy Report</h5></div>
                        <div className="col-md-6"><h5 style={{fontWeight:"bold"}}>Staff Timesheets</h5></div>
                    </div>
                    <div className="row mt-2" style={{width:"100%",marginLeft:"1%"}}>
                        <div className="col-md-6"><h5 style={{fontWeight:"bold"}}>Schedule Report</h5></div>
                        <div className="col-md-6"><h5 style={{fontWeight:"bold"}}>Employees Details</h5></div>
                    </div>
                    <div className="row mt-2" style={{width:"100%",marginLeft:"1%"}}>
                        <div className="col-md-6"><h5 style={{fontWeight:"bold"}}>Participants Services Report</h5></div>
                    </div>

                </div>
            </>
        )
    }
}