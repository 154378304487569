import React from 'react';
import {BrowserRouter,Routes, Route} from "react-router-dom";
import RosterView from '../views/roster'
import ReportView from '../views/reports'
import ParticipantView from '../views/participants'
import DashboardView from '../views/dashboard';
import Logs from '../components/SystemAudit';

export default class RoutesConfig extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/dashboard" element={<DashboardView />}/>
                    <Route exact path="/roster" element={<RosterView />}/>
                    <Route exact path="/participants" element={<ParticipantView />}/>
                    <Route exact path="/reports" element={<ReportView />}/>
                    <Route exact path="/audit" element={<Logs />}>
                </Route>
            </Routes>
          </BrowserRouter>
        )
      }
}