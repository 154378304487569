import React from 'react'
import axios from "axios"
import RosterController from '../controllers/roster';

export default class Controller extends React.Component {
    static getServicesStatisticsForTheWeek = async (data) =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "reports/services/?token=" + RosterController.extractToken().token + "&start=" + data.start + "&end=" + data.end);
            return res.data.res;
        }
        catch (error) {
            if (error.request) {
                return JSON.parse(error.request.response).res;
            }
            else {
                return error;
            }
        }
    }

    static getSfihtsStatisticsForTheWeek = async (data) =>{
        try {
            const res = await axios.get(RosterController.extractToken().api + "reports/shifts/?token=" + RosterController.extractToken().token + "&start=" + data.start + "&end=" + data.end);
            return res.data.res;
        }
        catch (error) {
            if (error.request) {
                return JSON.parse(error.request.response).res;
            }
            else {
                return error;
            }
        }
    }

}