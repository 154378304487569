import React from 'react'
import './assets/App.css';
import NavigationBar from './components/nav'
import Roster from './components/roster'
import Tools from './components/tools'
import RoutesConfig from './routes/routes'
export default class App extends React.Component {
  render() {
    return(
      <div style={{"height":`${window.screen.height*0.6}px`}}>
        <NavigationBar/>
        <RoutesConfig></RoutesConfig>
      </div>
      );
  }
}
