import React from 'react';
import axios from "axios";
import Swal from 'sweetalert2';


export default class RosterController extends React.Component {
    static extractToken() {
        var params = {};
        var parser = document.createElement('a');
        parser.href = window.location.href;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }
        const data = {
          token : params.token,
          api : params.api,
          main_system: params.main_system,
          name : params.name
        }
        return data;
    }

  //  static notifications = (data) =>{
  //       Swal.fire({
  //           position: 'top-end',
  //           // icon: 'success',
  //           html: data.message,
  //           showConfirmButton: false,
  //           timer: 2500,
  //           showClass: {
  //               popup: 'animate__animated animate__fadeInDown'
  //             },
  //             hideClass: {
  //               popup: 'animate__animated animate__fadeOutUp'
  //             }
  //       })
  //   }
    static notifications = (data) =>{
        document.getElementById("notifications").classList.remove('d-none');
        document.getElementById(`notifications`).innerHTML = data.message;
        if(data.isError){
          document.getElementById("notifications").classList.add('alert-danger');
        }else{
          document.getElementById("notifications").classList.add('alert-success');
        }
        
        document.getElementById("notifications").classList.add('alert');
        document.getElementById("notifications").classList.add('fade');
        document.getElementById("notifications").classList.add('show');
        document.getElementById(`notifications`).style.padding='0.2rem 0.2rem';
        document.getElementById(`notifications`).style.margin = '0px';
        document.getElementById(`notifications`).style.backgroundColor = '#a0e4c5';

        setTimeout(function() {
          document.getElementById(`notifications`).classList.add('d-none');
      }, 4400);
    }

    static saveShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }

    static copyAndPastWeekShitfs = async (data, period) =>{
        try {
            const res = await axios.post(`${this.extractToken().api}shift/copyweekshifts/?token=${this.extractToken().token}&start=${period.start}&end=${period.end}`, { weekToPaste: data });
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }

    static pasteShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/paste/?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static deleteShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/delete?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static approveShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/approve?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static confirmShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/confirm?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static updateShift = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/update?token=" + this.extractToken().token, data);
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static saveDraggedShifts = async (data) =>{
        try {
            const res = await axios.post(this.extractToken().api + "shift/dragge?token=" + this.extractToken().token, { data: data.draggeposition, ids: data.draggedshifts });
            this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${res.data.res} </div>`, isError : false});
            return res.data.res;
        }
        catch (error) {
          this.notifications({message:`<div class="text-success"><i class="bi bi-bell"></i> ${error} </div>`, isError : true});
          return error;
        }
    }
    static getParticipants = async () =>{
        try {
            const res = await axios.get(this.extractToken().api + "shift/participants?token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          return error;
        }
    }
    static getParticipantServices = async (id) =>{
        try {
            const res = await axios.get(this.extractToken().api + "shift/services?clientid=" + id + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          return error;
        }
    }

    static getParticpantShifts = async (data) => {
        try {
            const res = await axios.get(this.extractToken().api + "shift/?start=" + data.start + "&end=" + data.end + "&token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          return [];
        }
    }
    static getWorkers = async () => {
        try {
            const res = await axios.get(this.extractToken().api + "users/?token=" + this.extractToken().token);
            return res.data.res;
        }
        catch (error) {
          return [];
        }
    }
}