import React from "react";
import moment from "moment";
import "../assets/participant.css"
import RosterController from "../controllers/roster";
import SIL from "../components/paticipantSILModal";
import Controller from '../controllers/participants'

export default class Participants extends React.Component { 
    constructor(props) {
        super(props);
        this.state={
            participants:[],
            pageStart:0,
            pageEnd:0,
            pages:[],
            data:[],
            pageLength:13,
            page:1,
            openSILModal:false,
            sil:[],
            display:[]

        }
    }
    componentDidMount(){
        RosterController.getParticipants().then(res=>{
            this.setState({participants:res,data:res, pages:Array.from({length: Math.ceil(res.length/this.state.pageLength)}, (x, i) => i+1)});
            this.pagenation(1, res);
        }).catch(error=>{
            console.log(error)
        })
    }
    pagenation = (pos, data) =>{
        this.setState({display: data.slice(pos*this.state.pageLength-this.state.pageLength,pos*this.state.pageLength)})
    }
    searchParticipant = (name)=>{
        let data = this.state.participants.filter(value => {
            return (value.name.toLowerCase().includes(name.toLowerCase()));
        });
        this.setState({data: data, pages:Array.from({length: Math.ceil(data.length/this.state.pageLength)}, (x, i) => i+1)});
        this.pagenation(1, data);
    }
    render(){
        return(<>
            <div>
                <table   style={{marginTop:"1%"}} className="table table--roster table-borderless position-relative table-hover">
                    <thead style={{display:""}} className="thead-p-">
                        <tr className="tr-p"  style={{"borderBottom":"solid #000000"}}>
                            <th style={{width:"20%", "textAlign":"center"}} className=" table-col p" scope="col"><input onChange={(event)=>this.searchParticipant(event.target.value)} style={{"width":"90%"}} className="tool-button btn-sm form-control border-secondary me-2 bg-transparent" type="text" placeholder="Search Participant... " autoFocus={true}/></th>
                            <th style={{width:"4%"}} className="table-col p" scope="col" >ID</th>
                            <th style={{width:"6%", "textAlign":"center"}} className="table-col p" scope="col" >NDIS</th>
                            <th style={{width:"7%"}} className="table-col p" scope="col" >Plan Start</th>
                            <th style={{width:"7%"}} className="table-col p" scope="col" >Plan End</th>
                            <th style={{width:"28%", "textAlign":"center"}} className="table-col p" scope="col" >Address</th>
                            <th style={{width:"17%", "textAlign":"center"}} className="table-col p" scope="col" >email</th>
                            <th style={{width:"10%", "textAlign":"center"}} className="table-col p" scope="col" >Phone</th>
                            {/* <th style={{width:"6%",}} className="table-col p" scope="col" >Services</th>
                            <th style={{width:"8%", }} className="table-col p" scope="col" >Shifts </th> */}
                        </tr>
                    </thead>
                    <tbody className="tbody-p" style={{display:""}} >
                        {
                            this.state.display.map(item=>
                                <tr className="tr-p">
                                    <td style={{width:"20%"}} className="table-col p-r  " 
                                    onClick={()=>{
                                        // Controller.getSILDetails(item.id).then(res=>{
                                        //     this.setState({openSILModal:true, sil:res});
                                        // })
                                    }} > {item.name}</td>
                                    <td style={{width:"4%"}} className="table-col p-r " >{item.id}</td>
                                    <td style={{width:"6%"}} className="table-col p-r "  >{item.ndisnumber}</td>
                                    <td style={{width:"7%"}} className="table-col p-r "  >{moment(item.planstart).format("DD/MM/YYYY")!="Invalid date"?moment(item.planstart).format("DD/MM/YYYY"):"No start date"}</td>
                                    <td style={{width:"7%"}} className="table-col p-r"  >{moment(item.planend).format("DD/MM/YYYY")!="Invalid date"?moment(item.planend).format("DD/MM/YYYY"):"No end date"}</td>
                                    <td style={{width:"28%"}} className="table-col p-r"  >{item.address}</td>
                                    <td style={{width:"17%"}} className="table-col p-r "  >{item.email}</td>
                                    <td style={{width:"10%"}} className="table-col p-r "  >{item.phone}</td>
                                    {/* <td style={{width:"6%"}} className="table-col p-r"  ><a href="#">Link</a></td>
                                    <td style={{width:"8%"}} className="table-col p-r "  ><a href="#">Link</a></td> */}
                                </tr>
                            )
                        }
            
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" onClick={()=>{this.pagenation(this.state.page-1, this.state.data);this.setState({page:this.state.page-1});}}><a class="page-link bg-transparent" href="#">Previous</a></li>
                    {
                        this.state.pages.map(item=>
                            <li class={item==this.state.page?"page-item active ":"page-item"} onClick={()=>{this.setState({page:item});this.pagenation(item, this.state.data)}}><a class={item==this.state.page?"page-link":"page-link bg-transparent"}  href="#">{item}</a></li>
                        )
                    }
                    <li class="page-item" onClick={()=>{this.pagenation(this.state.page+1, this.state.data);this.setState({page:this.state.page+1});}}><a class="page-link bg-transparent" href="#">Next</a></li>
                </ul>
                </nav>
            </div>
            <SIL sil={this.state.sil} close={()=>this.setState({openSILModal:false})} generateSILShifts={Controller.generateSILShiftFromParticipantPlan} show={this.state.openSILModal}/>
        </>)
    }
}
